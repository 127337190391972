











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question523',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
        language: 'fr',
      },
      options: [
        {
          text: 'Enantiomers',
          value: 'enantiomers',
        },
        {
          text: 'Diastereomers',
          value: 'diastereoisomers',
        },
        {
          text: 'Same molecule',
          value: 'same',
        },
        {
          text: 'Constitutional Isomers',
          value: 'constitution',
        },
      ],
      optionsFr: [
        {
          text: 'Énantiomères',
          value: 'enantiomers',
        },
        {
          text: 'Diastéréoisomères',
          value: 'diastereoisomers',
        },
        {
          text: 'Même molécule',
          value: 'same',
        },
        {
          text: 'Isomères de Constitution',
          value: 'constitution',
        },
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/q523.png';
    },
  },
};
